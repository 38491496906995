import { styled } from "../stitches.config";
export const Text = styled("p", {
    borderLeft: "$borderWidths$input solid $inputBorder",
    m: "0",
    px: "$3",
    py: "$2",
    "&:last-of-type": {
        mb: "$4",
    },
    variants: {
        variant: {
            error: {
                borderColor: "$error",
                backgroundColor: "$errorBackground",
            },
            success: {
                borderColor: "$success",
                backgroundColor: "$successBackground",
            },
            info: {
                borderColor: "$info",
                backgroundColor: "$infoBackground",
            },
        },
    },
});
