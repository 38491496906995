import * as Collapsible from "@radix-ui/react-collapsible";
import { styled, keyframes } from "../../stitches.config";
import { Heading } from "../Heading";
export const StyledContainedLayout = styled("div", {
    backgroundColor: "$background",
    minHeight: "100%",
    maxWidth: "$containedMax",
    mx: "auto",
    p: "$3",
});
const open = keyframes({
    from: { height: 0 },
    to: { height: "var(--radix-collapsible-content-height)" },
});
const close = keyframes({
    from: { height: "var(--radix-collapsible-content-height)" },
    to: { height: 0 },
});
export const StyledContent = styled(Collapsible.CollapsibleContent, {
    zIndex: 1,
    gridRow: "2",
    gridColumn: "1 / span 3",
    overflow: "clip",
    overflowClipMargin: "4px",
    '&[data-state="open"]': {
        animation: `${open} 300ms ease-out`,
    },
    '&[data-state="closed"]': {
        animation: `${close} 300ms ease-out`,
    },
});
export const StyledLayoutHeader = styled(Collapsible.Root, {
    backgroundColor: "$background",
    display: "grid",
    gridTemplateColumns: "1fr auto 1fr",
    gridTemplateRows: "1fr",
    gridGap: "$space$3 $space$3",
    width: "100%",
    m: "0",
    mb: "$4",
    "&.menu-open": {
        gridTemplateColumns: "1fr auto 1fr",
        gridTemplateRows: "1fr auto 1fr",
        "& .view-heading": {
            mt: "$2",
            gridRow: 3,
            gridColumn: "1 / span 3",
        },
    },
    [`& ${Heading}`]: {
        m: 0,
    },
    variants: {
        contained: {
            true: {
                p: "$3",
                maxWidth: "$containedMax",
                mx: "auto",
                mb: "0",
            },
        },
    },
});
export const ConnectedDeviceLayout = styled("div", {
    minHeight: "100%",
    variants: {
        contained: {
            true: {
                maxWidth: "$containedMax",
                mx: "auto",
                p: "$3",
            },
        },
    },
});
export const StyledLayout = styled("div", {
    minHeight: "100vh",
    background: "$background",
    variants: {
        contained: {
            true: {
                maxWidth: "$containedMax",
                mx: "auto",
                p: "$3",
            },
        },
        device: {
            true: {
                userSelect: "none",
                "& *": {
                    userSelect: "none",
                },
            },
        },
    },
});
export const StyledLayoutHeading = styled(Heading, {
    gridRow: "1",
    gridColumn: "2",
});
export const StyledCollapsibleTrigger = styled(Collapsible.Trigger, {
    gridRow: "1",
    gridColumn: "3",
    justifySelf: "end",
});
