import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert } from "./Alert";
import { Button, ButtonGroup } from "./Button";
export function AlertErrorFallback({ error, resetErrorBoundary, }) {
    return (_jsx(Alert, Object.assign({ variant: "error", title: "Something went wrong:", message: error.message }, { children: _jsx(Button, Object.assign({ onClick: resetErrorBoundary }, { children: "Reset" })) })));
}
export function LayoutErrorFallback({ error }) {
    return (_jsx(Alert, Object.assign({ variant: "error", title: "Sorry something went wrong:", message: error.message }, { children: _jsxs(ButtonGroup, { children: [_jsx(Button, Object.assign({ onClick: () => {
                        window.history.go(-1);
                    } }, { children: "Go back" })), _jsx(Button, Object.assign({ onClick: () => {
                        window.location.reload();
                    } }, { children: "Reload page" }))] }) })));
}
