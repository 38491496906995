var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { styled, keyframes } from "../stitches.config";
import { Text } from "./Text";
import { Button } from "./Button";
const pulse = keyframes({
    from: {
        transform: "scale3d(1, 1, 1)",
    },
    "50%": {
        transform: "scale3d(1.05, 1.05, 1.05)",
    },
    to: {
        transform: "scale3d(1, 1, 1)",
    },
});
const StyledAlert = styled(Text, {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    mb: "$3",
    py: "$3",
    animation: `${pulse} 200ms ease-out`,
    "& .contents": {
        display: "grid",
    },
    "&:last-of-type": {
        mb: "$3",
    },
    "& .title": {
        mb: "$3",
    },
    "& .message": { p: 0, m: 0, alignSelf: "center", " + *": { mt: "$1" } },
});
export const Alert = (_a) => {
    var { title, message, children } = _a, props = __rest(_a, ["title", "message", "children"]);
    const [closed, setClosed] = useState(false);
    return closed ? null : (_jsxs(StyledAlert, Object.assign({ as: "div", role: "alert" }, props, { children: [_jsxs("div", Object.assign({ className: "contents" }, { children: [title && _jsx("strong", Object.assign({ className: "title" }, { children: title })), _jsx("p", Object.assign({ className: "message" }, { children: message })), children] })), _jsx("div", { children: _jsx(Button, Object.assign({ size: "small", fit: true, onClick: () => {
                        setClosed(true);
                    } }, { children: "Close" })) })] })));
};
