var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useState, } from "react";
import { ErrorBoundary } from "react-error-boundary";
import invariant from "tiny-invariant";
import { Button, ButtonGroup } from "../Button";
import { Heading } from "../Heading";
import { LayoutErrorFallback } from "../ErrorFallback";
import { StyledCollapsibleTrigger, StyledContent, StyledLayout, StyledLayoutHeader, StyledLayoutHeading, } from "./Layout.styled";
export const LayoutHeading = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    return (_jsx(StyledLayoutHeading, Object.assign({ className: "view-heading" }, rest, { children: children })));
};
const LayoutToggleMenuContext = createContext(null);
export const useToggleLayoutContext = () => {
    const context = useContext(LayoutToggleMenuContext);
    invariant(context, "useToggleLayoutContext must be used within a Layout");
    return context;
};
export const LayoutHeader = (_a) => {
    var { title, children, className = "", logo } = _a, rest = __rest(_a, ["title", "children", "className", "logo"]);
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenuOpen = useCallback(() => {
        setMenuOpen((s) => !s);
    }, []);
    return (_jsxs(StyledLayoutHeader, Object.assign({ className: menuOpen ? `${className} menu-open` : className, open: menuOpen }, rest, { children: [_jsx("div", { children: logo }), menuOpen && _jsx(Heading, Object.assign({ as: "h1" }, { children: "Menu" })), _jsx(LayoutHeading, { children: title }), _jsx(StyledCollapsibleTrigger, Object.assign({ asChild: true, onClick: toggleMenuOpen }, { children: _jsx(Button, { children: menuOpen ? "Close" : "Menu" }) })), _jsx(StyledContent, { children: _jsx(ButtonGroup, Object.assign({ direction: "column", as: "nav" }, { children: _jsx(LayoutToggleMenuContext.Provider, Object.assign({ value: toggleMenuOpen }, { children: children })) })) })] })));
};
export const Layout = (_a) => {
    var { children, head, title, hideHeader, navigation, logo } = _a, rest = __rest(_a, ["children", "head", "title", "hideHeader", "navigation", "logo"]);
    return (_jsxs(StyledLayout, Object.assign({}, rest, { children: [((rest.device && title) || !rest.device) && !hideHeader && (_jsx(LayoutHeader, Object.assign({ contained: !rest.contained, title: title, logo: logo }, { children: navigation }))), _jsx(ErrorBoundary, Object.assign({ FallbackComponent: LayoutErrorFallback }, { children: _jsx(_Fragment, { children: children }) }))] })));
};
