import { styled, keyframes } from "../stitches.config";
const active = keyframes({
    from: { bottom: 0, left: 0 },
    to: { bottom: -2, left: -2 },
});
const hoverIn = keyframes({
    from: { outlineWidth: 0 },
    to: { outlineWidth: "2" },
});
export const Button = styled("button", {
    position: "relative",
    fontFamily: "$normal",
    border: "$borderWidths$button solid $buttonBorder",
    background: "$buttonBackgroud",
    color: "$buttonText",
    padding: "$2 $3",
    fontSize: "$2",
    cursor: "pointer",
    width: "100%",
    "&:hover:not(:focus)": {
        outlineColor: "blue",
        outlineStyle: "solid",
        animation: `${hoverIn} 50ms ease-out`,
    },
    "&:active": {
        animation: `${active} 100ms ease-out`,
        bottom: -2,
        left: -2,
    },
    "&:focus": {
        outline: "blue 2px solid",
    },
    "&:disabled": {
        cursor: "auto",
        opacity: "0.6",
    },
    variants: {
        fit: {
            true: {
                width: "fit-content",
                height: "fit-content",
            },
        },
        size: {
            small: {
                fontSize: "$1",
                padding: "$1 $2",
            },
        },
    },
});
export const ButtonGroup = styled("div", {
    display: "flex",
    width: "100%",
    "& > * + *": { ml: "$2" },
    variants: {
        direction: {
            column: {
                flexDirection: "column",
                "& > * + *": { mt: "$2", ml: 0 },
            },
        },
    },
});
