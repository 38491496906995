import { ComponentProps } from "react";
import NextLink from "next/link";
import NextImage from "next/image";

import { Layout } from "ui/dist/components/Layout";
import { NavigationLinks } from "./NavigationLinks";
import logoIcon from "ui/dist/assets/logo-48x48.png";

export const AppLayout = ({
    children,
    ...props
}: ComponentProps<typeof Layout>) => {
    return (
        <Layout
            navigation={<NavigationLinks />}
            logo={
                <NextLink href="/">
                    <a
                        style={{
                            cursor: "pointer",
                        }}
                    >
                        <NextImage
                            src={logoIcon}
                            alt="Crypto Coin & Token Ticker"
                            layout="fixed"
                            quality={100}
                        />
                    </a>
                </NextLink>
            }
            {...props}
        >
            {children}
        </Layout>
    );
};
