import { Button } from "ui/dist/components/Button";
import { useToggleLayoutContext } from "ui/dist/components/Layout/Layout";
import { useDarkMode } from "ui/dist/hooks/darkMode";
import { supabasePublic } from "../lib/supabasePublic";
import { AppLink } from "./AppLink";
import { useUser } from "./UserContext";

export const NavigationLinks = () => {
    const toggleMenuOpen = useToggleLayoutContext();

    const [darkMode, toggleDarkMode] = useDarkMode();

    const user = useUser();

    return (
        <>
            {user ? (
                <>
                    <AppLink href="/home" button onClick={toggleMenuOpen}>
                        Home
                    </AppLink>
                    <AppLink href="/tickers" button onClick={toggleMenuOpen}>
                        Tickers
                    </AppLink>
                    <AppLink href="/devices" button onClick={toggleMenuOpen}>
                        Devices
                    </AppLink>
                    <AppLink href="/account" button onClick={toggleMenuOpen}>
                        Account
                    </AppLink>
                    <Button onClick={toggleDarkMode}>{`${
                        darkMode ? "Light" : "Dark"
                    } mode`}</Button>
                    <Button
                        onClick={async () => {
                            await supabasePublic.auth.signOut();
                        }}
                    >
                        Logout
                    </Button>
                </>
            ) : (
                <>
                    <AppLink href="/" button onClick={toggleMenuOpen}>
                        Home
                    </AppLink>
                    <AppLink href="/login" button onClick={toggleMenuOpen}>
                        Login
                    </AppLink>
                    <AppLink href="/signup" button onClick={toggleMenuOpen}>
                        Signup
                    </AppLink>
                </>
            )}
        </>
    );
};
